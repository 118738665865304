<template>
  <dash-page-new
      root="Dashboard"
      :root-location="businessDashLink('')"
      subtitle="MobileApps"
      title="Statistics"
      background-color="var(--wsLIGHTCARD)"
      no-body-padding
  >

    <template #header.after>

      <div class="d-flex overflow-hidden mt-4" style="grid-gap: 16px">
        <v-sheet
            v-for="item in chips" :key="item.id"
            min-width="250px"
            width="100%"

            class="wsRoundedSemi pa-4"
            style="border: 1px solid var(--wsBACKGROUND); border-top: 4px solid var(--wsBACKGROUND);"
        >
          <h5 class="wsACCENT mb-1">{{  item.title  }}</h5>
          <span v-if="item.id !== 'all'">
              <h3 class="wsACCENT">{{ item.value }} пристроя </h3>
              <h4  class="wsACCENT font-weight-regular">{{ item.percent }}  %</h4>
          </span>
          <span v-else>
              <h3 class="wsACCENT">{{ item.value }} користувачів </h3>
              <h4  class="wsACCENT font-weight-regular">{{ item.total }} пристроїв</h4>
          </span>

        </v-sheet>
      </div>
    </template>

    <template #default>
      <ws-navigation-header
          v-model="navigation"
          :items="navigationSelect"
          no-number
          padding="1px"
      />
      <v-sheet height="100%" class="py-5 px-10">
        <ws-data-table
            :key="navigation"
            :items="itemsFiltered"
            :headers="headers"
            style="border: 1px solid var(--wsBACKGROUND); border-radius: 8px"
        >

          <template #item.date="{item}">
             <h5> {{ $time(item.date).regularTime() }}</h5>
          </template>

          <template #item.email="{item}">
            <h5>{{ item.name }}</h5>
            <h5 class="wsACCENT font-weight-regular">
              {{ item.email }}
            </h5>
          </template>

        </ws-data-table>
      </v-sheet>
    </template>


  </dash-page-new>
</template>


<script>
import {mapActions} from "vuex";

export default {
  name: "DashMobileAppsInAppPurchase",
  data() {
    return {
      navigation : 'all',
      items : []
    }
  },
  computed : {
    androidUsers() {
      return this.items.filter(el => el.app === 'ANDROID' ).length || 0
    },
    appleUsers() {
      return this.items.filter(el => el.app === 'APPLE' ).length || 0
    },
    appleUsersPercent() {
      if (!this.items.length) {
        return 0
      }
      return ((this.appleUsers/this.items.length)*100).toFixed(0) || 0
    },
    androidUsersPercent() {
      if (!this.items.length) {
        return 0
      }
      return ((this.androidUsers/this.items.length)*100).toFixed(0) || 0
    },
    uniqueUsers() {
      if (!this.items.length) {
        return 0
      }
      return new Set(this.items.map(item => item.email)).size;
    },
    chips() {
      return [
        { title : this.$t('TotalUsers') , id : 'all' , value : this.uniqueUsers  , total : this.items.length  },
        { title : this.$t('Apple')      , id : 'apple' , value :  this.appleUsers , percent : this.appleUsersPercent  },
        { title : this.$t('Android')    , id : 'android' , value : this.androidUsers , percent : this.androidUsersPercent },
      ]
    },
    itemsFiltered() {
      return this.navigation === 'all' ? this.items : this.items.filter(el => el.app === this.navigation )
    },
    headers() {
      let items = [
        { text : 'User' , value : 'email'                             },
        { text : 'App' , value : 'app'                , width : 100 , },
        { text : 'AppVersion' , value : 'app_version' , width : 50  , align : 'center'},
        { text : 'device' , value : 'model'           , width : 100 ,                 },
        { text : 'OsVersion' , value : 'os_version'   , width : 100 ,                 },
        { text : 'date' , value : 'date'              , width : 160 ,                 },
      ]

      if (this.navigation !== 'all') {
        items = items.filter(el => el.value !== 'app' )
      }

      return items
    },
    navigationSelect() {
      return [
        { text : this.$t('Total') , value : 'all'},
        { text : this.$t('iPhoneUsers') , value : 'APPLE'},
        { text : this.$t('AndroidUsers') , value : 'ANDROID'}
      ]
    },
  },
  methods : {
    ...mapActions('mobileapps' , [
      'GET_MOBILE_STATS',
    ]),

    async initPage() {
      let result = await this.GET_MOBILE_STATS()
      if (!result) {
        return this.ERROR()
      }

      this.items = result
    }
  },

  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>
